<template>
    <div>
      <div style="padding: 20px;">
        <h4 style="float: left;">Contacts [{{ contacts.resultCount  }}]</h4>
        <div style="float: right;">
          <button v-if="!this.showForm" v-on:click="this.showForm = true" class="btn btn-sm pd-x-15 btn-white">
            <i data-feather="mail" class="wd-10 mg-r-5"></i> Add contact
          </button>
        </div>
      </div>
      <div style="padding-top: 20px;">
        <AddAccountContactForm v-if="this.showForm" 
          @formsubmitted="getByAccountIdentifer"
          :accountIdentifier="this.accountIdentifier"
            />    
        <button v-if="this.showForm" v-on:click="this.showForm = false">cancel</button>    
      </div>
      <div style="padding-top: 25px;">
        <FeedbackNotification style="margin-bottom: 30px;" v-if="this.notification.text != ''" 
          :feedbackText="this.notification.text" 
          :feedbackLevel="this.notification.level"/> 
        <div v-if="isPageLoading">
          <LoaderView></LoaderView>
        </div>
        <div v-else-if="contacts.resultCount > 0">
          <table class="table table-invoice bd-b">
              <thead style="border-bottom: 2px solid #ccc;">
                <tr>
                  <th class="tx-left"><b>Name</b></th>
                  <th class="tx-right"><b>Status</b></th>
                  <th class="tx-right"><b>Date Created</b></th>
                  <th class="tx-right"><b></b></th>
                </tr>
              </thead>
              <tbody>
                <tr v-bind:key="contact.Identifier" v-for="contact in contacts.results">
                  <td class="tx-left">{{ contact.Name }}</td>
                  <td class="tx-right">{{ contact.Status }}</td>
                  <td class="tx-right">{{ new Date(contact.DateCreated).toLocaleDateString() }}</td>
                  <td class="tx-right">
                    <a href="#" v-on:click="contactClick(contact.Identifier)">view contact</a>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
        <div v-else>
          <FeedbackNotification style="margin-bottom: 30px;" 
            feedbackText="No contacts found" 
            feedbackLevel="notification"/> 
      </div>
      </div>
    </div>
  </template>
  
  <script>
  import { contactAPI } from "@/api/contact";
  import AddAccountContactForm from "@/components/Forms/AddAccountContactForm.vue";
  import FeedbackNotification from "@/components/FeedbackNotification.vue";
  import LoaderView from "@/components/LoaderView.vue";
  import { useStatus } from "@/api/composables/status";
  import { useErrorLogger } from "@/api/composables/errorLogging";
  
  const pageStatus = useStatus();
  const log = useErrorLogger();
  
  export default {
    props: ["accountIdentifier"],
    components: { FeedbackNotification, AddAccountContactForm, LoaderView },
    data() {
      return {
        showForm : false,
        contacts : {
          results : [],
          resultCount: null,
          pageSize: 0,
          pageNumber: 1
        },
        isPageLoading : true,      
        notification : {
          text : "",
          level : "",
        },
      };
    },
   created(){
      this.getByAccountIdentifer()
   },
    methods: {
      async getByAccountIdentifer() {
        this.showForm = false;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await contactAPI.getByAccountIdentifer(this.accountIdentifier);
              this.contacts.results = result.Results;
              this.contacts.resultCount = result.ResultCount;
              this.contacts.pageSize = result.PageSize;
              this.contacts.pageNumber = result.PageNumber; 

              console.log(this.contacts)
          })
        );
        this.isPageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };          
        }
      },
      async contactClick(contactIdentifier) {
        this.$router.push('/contacts/' + contactIdentifier);
      },
    },
  };
  </script>